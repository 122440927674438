<template>
  <div v-loading="isLoading" class="page">
    <div class="tabs-wrap">
      <el-tabs
          v-model="currentTab"
          :before-leave="onBeforeLeave"
          type="border-card"
      >
        <el-tab-pane label="基本信息" name="basic">
          <BasicInfo :settlementModelJSON="settlementModelJSON" @success="onSuccess"/>
        </el-tab-pane>
        <el-tab-pane label="营业信息" name="business">
          <BusinessInfo :settlementId="settlementId"/>
        </el-tab-pane>
        <el-tab-pane label="联系人信息" name="contacts">
          <ContactsInfo :settlementId="settlementId"/>
        </el-tab-pane>
      </el-tabs>
      <el-button class="back-button" type="text" @click="goBack"
      ><i class="el-icon-back"></i>返回
      </el-button
      >
    </div>
  </div>
</template>

<script>
import BasicInfo from "./components/basic.vue";
import BusinessInfo from "./components/business.vue";
import ContactsInfo from "./components/contacts.vue";
import {findBaseSettlementInfoById} from "@/api/settlementManage";

export default {
  name: "add_settlement",
  components: {
    BasicInfo,
    BusinessInfo,
    ContactsInfo,
  },
  data() {
    return {
      settlementId: "",
      currentTab: "basic",
      settlementModelJSON: "",
      isLoading: false,
    };
  },
  methods: {
    //返回
    goBack() {
      this.$router.back();
    },
    //检查是否有settlementId，没有不允许切换选项卡
    onBeforeLeave() {
      if (!this.settlementId) {
        this.$message({
          message: "请先完成结算方基本信息",
          type: "warning",
        });
        return false;
      }
      return true;
    },

    //查询当前结算方的基本信息
    getBaseSettlementInfo(id) {
      this.isLoading = true;
      findBaseSettlementInfoById(id).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          const returnObject = res.returnObject;
          this.settlementModelJSON = JSON.stringify(returnObject);
          // window.sessionStorage.setItem(
          //   "BASE_SETTLEMENT_INFO",
          //   JSON.stringify(returnObject)
          // )
        }
      })
    },

    //保存成功生成的结算方id
    onSuccess(e) {
      this.settlementId = e.id;
    },

  },
  mounted() {
    const settlementId = this.$route.query.id;
    if (settlementId) {
      this.settlementId = settlementId;
      this.getBaseSettlementInfo(this.settlementId);
    }
  },
};
</script>

<style lang="less" scoped>
.tabs-wrap {
  position: relative;

  .back-button {
    position: absolute;
    right: 20px;
    top: 0;
    font-size: 15px;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 18px;

    i {
      font-size: 18px;
    }
  }
}
</style>
