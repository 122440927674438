import { render, staticRenderFns } from "./contacts.vue?vue&type=template&id=2fd3b4ce&scoped=true"
import script from "./contacts.vue?vue&type=script&lang=js"
export * from "./contacts.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fd3b4ce",
  null
  
)

export default component.exports