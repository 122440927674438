<template>
  <div v-loading="isLoading" class="add-settlement-contacts">
    <el-form
        ref="model"
        :model="model"
        :rules="rules"
        label-width="200px"
        size="small"
    >
      <easy-card title="联系人信息">
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系人姓名" prop="contactName" required>
              <el-input
                  v-model="model.contactName"
                  placeholder="请输入联系人姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系方式" prop="contactDetails" required>
              <el-input
                  v-model="model.contactDetails"
                  placeholder="请输入联系方式"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="邮箱" prop="mailbox">
              <el-input
                  v-model="model.mailbox"
                  placeholder="请输入邮箱"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="职务" prop="jobTitle">
              <el-input
                  v-model="model.jobTitle"
                  placeholder="请输入职务"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div>
          <el-button class="xxd-button_save" type="primary" @click="add"
          >添加
          </el-button
          >
        </div>
      </easy-card>
    </el-form>
    <el-table
        v-loading="isLoading"
        :data="dataList"
        border
        class="common-table"
        size="medium"
        style="width: 100%"
    >
      <el-table-column label="联系人姓名" prop="contactName">
      </el-table-column>
      <el-table-column label="联系方式" prop="contactDetails">
      </el-table-column>
      <el-table-column label="邮箱" prop="mailbox"></el-table-column>
      <el-table-column label="职务" prop="jobTitle"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
              class="common-text"
              size="small"
              type="text"
              @click="goDelete(scope.row)"
          >删除
          </el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top:30px;">
      <el-button class="xxd-button_save" type="primary" @click="save"
      >保存
      </el-button
      >
    </div>
  </div>
</template>

<script>
import {createContactInformation, findContactInformationBySettlementId} from "@/api/settlementManage";

export default {
  props: {
    settlementId: String,
  },
  data() {
    return {
      isLoading: false,
      // settlementId:"",
      model: {
        contactName: "",
        contactDetails: "",
        mailbox: "",
        jobTitle: "",
        id: "",
      },
      rules: {
        contactName: [{required: true, message: "请填写联系人姓名"}],
        contactDetails: [
          {required: true, message: "请填写联系方式"},
          {required: true, pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '手机号格式不正确'}
        ]
      },
      dataList: [],
    };
  },
  methods: {
    //添加到列表数据
    add() {
      this.$refs.model.validate((valid) => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.model));
          this.dataList.push(data);
          this.$refs.model.resetFields();
        } else {
          return false;
        }
      });
    },
    //删除列表数据
    goDelete(e) {
      const dataList = this.dataList;
      dataList.splice(dataList.indexOf(e), 1);
    },
    //保存
    save() {
      const contactInformationVoList = this.dataList;
      const body = {
        settlementId: this.settlementId,
        contactInformationVoList: contactInformationVoList,
      }
      console.log(body);
      this.isLoading = true;
      createContactInformation(body).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          this.$message({
            message: "保存结算方联系人信息成功!",
            type: "success",
          });
        }
      })
    },

    //编辑———查询当前联系人信息
    getContactInfo(id) {
      this.isLoading = true;
      findContactInformationBySettlementId(id).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          const returnObject = res.returnObject;
          this.dataList = returnObject;
        }
      })
    }
  },
  mounted() {
    const settlementId = this.$route.query.id;
    if (settlementId) {
      // this.settlementId = settlementId;
      this.getContactInfo(settlementId);
    }
  }
};
</script>

<style lang="less" scoped>

</style>
