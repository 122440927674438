<template>
  <div v-loading="isLoading" class="add-settlement-basic">
    <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="200px"
        size="small"
    >
      <el-form-item
          class="detail-item"
          label="结算方名称"
          prop="settlementName"
          required
      >
        <el-input
            v-model="form.settlementName"
            placeholder="请输入结算方名称"
        ></el-input>
      </el-form-item>

      <el-form-item
          class="detail-item"
          label="选择城区"
          prop="regionId"
          required
      >
        <el-cascader v-model="form.regionId" :clearable="true" :filterable="true" :props="props"
                     :show-all-levels="false" placeholder="请选择区域"/>
      </el-form-item>

      <el-form-item
          class="detail-item"
          label="主体类型"
          prop="subjectType"
          required
      >
        <el-select v-model="form.subjectType" style="width: 380px">
          <el-option label="企业" value="1"></el-option>
          <el-option label="个体户" value="2"></el-option>
          <el-option label="个人" value="3"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
          class="detail-item"
          label="详细地址"
          prop="address"
          required
      >
        <el-input
            v-model="form.address"
            placeholder="请输入详细地址"
        ></el-input>
      </el-form-item>

      <el-form-item class="detail-item" label="登录手机号" prop="tel" required>
        <el-input v-model="form.tel" placeholder="请输入登录手机号"></el-input>
      </el-form-item>

      <!-- <el-form-item label="关联商户" prop="merchantId" class="detail-item">
        <Bearer v-model="form.merchantId" placeholder="请选择商户名称" style="width: 380px" />
      </el-form-item> -->

      <el-button class="xxd-button_save" type="primary" @click="save"
      >保存
      </el-button
      >
    </el-form>
  </div>
</template>

<script>
// import Bearer from "@/components/Bearer";
import {createOrUpdateBaseSettlementInfo} from "@/api/settlementManage";
import {getRegionList} from "@/api/user";

export default {
  // components: {
  //   Bearer,
  // },
  props: {
    settlementModelJSON: String,
  },
  data() {
    return {
      defaultRegion: [],
      props: {
        lazy: true,
        async lazyLoad(node, resolve) {
          const {level, value} = node;
          console.log('node.value:', value)
          let regionId = level === 0 ? 0 : value;
          getRegionList({regionId: regionId})
              .then(({returnObject}) => {
                console.log('getRegionList:', returnObject)
                const nodes = returnObject.map(item => ({
                      value: item.id,
                      label: item.name,
                      leaf: item.level == 3,
                    })
                )
                resolve(nodes)
              })
        }
      },
      isLoading: false,
      form: {
        id: "",
        settlementName: "",
        regionId: "530129",
        subjectType: "1",
        address: "",
        tel: "",
        merchantId: "",
      },
      rules: {
        settlementName: [{required: true, message: "结算方名称不能为空"}],
        regionId: [{required: true, message: "请选择城区"}],
        subjectType: [{required: true, message: "请选择主体类型"}],
        address: [{required: true, message: "详细地址不能为空"}],
        tel: [{required: true, message: "登录手机号不能为空"}],
      },
    };
  },
  watch: {
    //编辑--查询当前信息
    settlementModelJSON(newVal) {
      if (newVal !== "") {
        const returnObject = JSON.parse(newVal);
        this.form = {
          id: returnObject.id,
          settlementName: returnObject.settlementName,
          regionId: returnObject.regionId,
          subjectType: returnObject.subjectType,
          address: returnObject.address,
          tel: returnObject.tel,
          merchantId: returnObject.merchantId,
        };
      }
    },
  },
  methods: {
    //保存结算方基本信息
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const body = {
            address: this.form.address,
            id: this.form.id,
            merchantId: this.form.merchantId,
            regionId: this.form.regionId,
            settlementName: this.form.settlementName,
            subjectType: this.form.subjectType,
            tel: this.form.tel,
          };
          this.isLoading = true;
          createOrUpdateBaseSettlementInfo(body).then((res) => {
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "保存结算方基本信息成功!",
                type: "success",
              });
              this.$emit("success", res.returnObject);
              sessionStorage.setItem("save_success", "1");
            }
          });
        }
      });
    },
  },
  // mounted() {
  //   const settlementId = this.$route.query.id;
  //   if(settlementId) {
  //     this.id = settlementId;
  //     this.getBaseSettlement(settlementId);
  //   }
  // }
};
</script>

<style lang="less" scoped>
.add-settlement-basic {
  margin-top: 30px;
}

.detail-item {
  width: 40%;
  margin: 0 auto;
  margin-bottom: 30px;
}
</style>
