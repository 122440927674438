<template>
  <div v-loading="isLoading" class="add-settlement-business">
    <el-form
        ref="model"
        :model="model"
        :rules="rules"
        label-width="200px"
        size="small"
    >
      <easy-card title="企业资质">
        <el-row>
          <el-col :span="12">
            <el-form-item
                label="营业执照编号"
                prop="businessLicenseNumber"
                required
            >
              <el-input
                  v-model="model.businessLicenseNumber"
                  placeholder="请输入营业执照编号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="税号" prop="taxId">
              <el-input
                  v-model="model.taxId"
                  placeholder="请输入税号"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item
                label="营业执照图片"
                prop="businessLicenseAttachmentId"
                required
            >
              <el-upload
                  ref="licenseImageUpload"
                  :file-list="licenseFileList"
                  :http-request="doLicenseImageUpload"
                  :limit="1"
                  :on-exceed="licenseHandleExceed"
                  :on-remove="onLicenseImageRemove"
                  action=""
                  list-type="picture-card"
              >
                <span>点击上传</span>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="法人身份证" prop="legalPersonId">
              <el-upload
                  ref="legalPersonImageUpload"
                  :file-list="legalPersonFileList"
                  :http-request="doLegalPersonUpload"
                  :limit="1"
                  :on-exceed="legalHandleExceed"
                  :on-remove="onLegalPersonImageRemove"
                  action=""
                  list-type="picture-card"
              >
                <span>点击上传</span>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </easy-card>

      <easy-card title="开户信息">
        <el-row>
          <el-col :span="12">
            <el-form-item label="开户行类型" prop="bankType" required>
              <el-select
                  v-model="model.bankType"
                  placeholder="请选择开户行类型"
                  style="width: 100%"
              >
                <el-option label="农业银行" value="1"></el-option>
                <el-option label="其他银行" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
                label="银行总行行号"
                prop="bankHeadOfficeNumber"
                required
            >
              <el-input
                  v-model="model.bankHeadOfficeNumber"
                  placeholder="请输入银行总行行号"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item
                label="银行总行名称"
                prop="bankHeadOfficeName"
                required
            >
              <el-input
                  v-model="model.bankHeadOfficeName"
                  placeholder="请输入银行总行名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
                label="开户分行名称"
                prop="accountOpeningBranchName"
                required
            >
              <el-input
                  v-model="model.accountOpeningBranchName"
                  placeholder="请输入开户行"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="账号" prop="account" required>
              <el-input
                  v-model="model.account"
                  placeholder="请输入账号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="银行开户名" prop="bankAccountName" required>
              <el-input
                  v-model="model.bankAccountName"
                  placeholder="请输入开户名"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </easy-card>

      <easy-card title="结算规则">
        <el-row>
          <el-col :span="12">
            <el-form-item label="结算账期(天)" prop="accountPeriod" required>
              <el-input
                  v-model="model.accountPeriod"
                  placeholder="请输入结算账期(数字)"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="银联提现费率" prop="withdrawalRate" required>
              <el-input
                v-model="model.withdrawalRate"
                placeholder="请输入银联提现费率 例：(0.006)"
              ></el-input>
            </el-form-item>
          </el-col> -->
        </el-row>

        <!-- <el-row>
          <el-form-item label="银联提现手续费" prop="commissionLadderList" required>
            <el-row v-for="item in model.commissionLadderList" :key="item.id" style="margin-bottom:10px">
            <el-col :span="4">
              <el-input v-model="item.commissionStart" disabled>
                <template slot="append">元</template>
              </el-input>
            </el-col>
            <el-col :span="0.5">
              <span class="input-margin">~</span>
            </el-col>
            <el-col :span="4" class="input-margin">
              <el-input v-model="item.commissionEnd" :disabled="item.isDisabled">
                <template slot="append">元</template>
              </el-input>
            </el-col>
            <el-col :span="4" class="input-margin">
              <el-input v-model="item.price">
                <template slot="append">元/笔</template>
              </el-input>
            </el-col>
            </el-row>
          </el-form-item>
          <div class="table-btn">
                    <el-button
                      type="primary"
                      size="small"
                      icon="el-icon-plus"
                      circle
                      @click="addLadder"
                    ></el-button>
                    <el-button
                      size="small"
                      icon="el-icon-minus"
                      circle
                      @click="deleteLadder"
                    ></el-button>
                  </div>
        </el-row> -->

        <!-- <el-row>
          <el-col :span="12">
            <el-form-item label="首次结算时间" prop="settlementTime">
              <el-date-picker
                v-model="model.settlementTime"
                type="date"
                placeholder="请选择首次结算时间"
                style="width: 904px"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="服务费发票" prop="invoiceType" required>
              <el-select
                placeholder="请选择发票类型"
                v-model="model.invoiceType"
                style="width: 904px"
              >
                <el-option value="1" label="增值税普票"></el-option>
                <el-option value="2" label="不开票"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row> -->
      </easy-card>
      <el-button class="xxd-button_save" type="primary" @click="save"
      >保存
      </el-button
      >
    </el-form>
  </div>
</template>

<script>
import {doUploadImage, getParkingImage} from "@/api/parking";
import {createOrUpdateBusinessInformation, findBusinessInformation,} from "@/api/settlementManage";

export default {
  props: {
    settlementId: String,
  },
  data() {
    return {
      isLoading: false,
      licenseFileList: [], //营业执照图片列表
      legalPersonFileList: [], //法人身份证图片
      enterpriseQualificationId: "", //企业资质id
      accountInfoId: "", //开户信息id
      // settlementId: "", //结算方id
      model: {
        businessLicenseNumber: "",
        taxId: "",
        businessLicenseAttachmentId: [],
        legalPersonId: [],
        bankType: "",
        bankHeadOfficeNumber: "",
        bankHeadOfficeName: "",
        accountOpeningBranchName: "",
        account: "",
        bankAccountName: "",
        accountPeriod: "",
        // commissionLadderList:[],//手续费阶梯列表
        // settlementTime: "",
        // invoiceType: "",
      },
      rules: {
        businessLicenseNumber: [
          {required: true, message: "营业执照编号不能为空"},
        ],
        businessLicenseAttachmentId: [
          {required: true, message: "请上传营业执照图片"},
        ],
        bankType: [{required: true, message: "请选择开户行类型"}],
        bankHeadOfficeNumber: [
          {required: true, message: "银行总行行号不能为空"},
        ],
        bankHeadOfficeName: [
          {required: true, message: "银行总行名称不能为空"},
        ],
        accountOpeningBranchName: [
          {required: true, message: "开户行名称不能为空"},
        ],
        account: [{required: true, message: "账号不能为空"}],
        bankAccountName: [{required: true, message: "开户行名称不能为空"}],
        accountPeriod: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (value === "") {
                callback(new Error("结算账期不能为空"));
              } else if (!/(^[1-9]\d*$)/.test(value)) {
                callback(new Error("请输入正整数"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 营业执照图片删除
    onLicenseImageRemove(file, fileList) {
      this.model.businessLicenseAttachmentId = fileList.map((item) => item.id);
    },
    onLegalPersonImageRemove(file, fileList) {
      this.model.legalPersonId = fileList.map((item) => item.id);
    },
    // 批量获取营业执照图片列表
    getAllLicenseImages(attachmentIds) {
      const requestList = attachmentIds.map((item) => {
        return this.getImage(item);
      });

      Promise.all(requestList).then((res) => {
        this.licenseFileList = res;
      });
    },
    //批量获取法人身份证图片
    getAllLegalPersonImages(attachmentIds) {
      const requestList = attachmentIds.map((item) => {
        return this.getImage(item);
      });

      Promise.all(requestList).then((res) => {
        this.legalPersonFileList = res;
      });
    },
    async getImage(attachmentId) {
      const res = await getParkingImage(attachmentId);
      if (res && res.code === 30 && res.result) {
        return {
          url: res.returnObject,
          id: attachmentId,
        };
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader(); //定义方法读取文件
        reader.readAsDataURL(file); //开始读文件  本身是图片的二进制数据 进行base64加密形成字符串
        reader.onload = () => resolve(reader.result); //成功返回对应的值 reader.result可以直接放在img标签中使用
        reader.onerror = (error) => reject(error); //失败返回失败信息
      });
    },
    // 上传营业执照图片
    async doLicenseImageUpload(file) {
      const isLimit = file.file.size / 1024 < 1000;
      if (!isLimit) {
        this.$message.warning("图片大小不能超过1M! 请重新上传");
        // this.$refs.graphImageUpload.clearFiles();
        return;
      }
      const base64 = await this.getBase64(file.file);
      const res = await doUploadImage(base64);
      if (res && res.code === 30 && res.result) {
        this.model.businessLicenseAttachmentId.push(res.returnObject.id);
      }
    },

    // 上传法人身份证图片
    async doLegalPersonUpload(file) {
      const isLimit = file.file.size / 1024 < 1000;
      if (!isLimit) {
        this.$message.warning("图片大小不能超过1M! 请重新上传");
        // this.$refs.graphImageUpload.clearFiles();
        return;
      }
      const base64 = await this.getBase64(file.file);
      const res = await doUploadImage(base64);
      if (res && res.code === 30 && res.result) {
        this.model.legalPersonId.push(res.returnObject.id);
      }
    },
    //限制图片上传的个数
    licenseHandleExceed() {
      this.$message.warning("营业执照图片最多上传一张");
    },
    legalHandleExceed() {
      this.$message.warning("法人身份证最多上传一张");
    },
    //  //阶梯列表生成
    // foundLadderList() {
    //   const list = [];
    //   list.push({
    //     commissionStart: 0,
    //     commissionEnd: "",
    //     price: "",
    //     isDisabled: false,
    //   });
    //   this.model.commissionLadderList = list;
    // },
    // //添加一行阶梯计费
    // addLadder() {
    //   const list = this.model.commissionLadderList;
    //   const reg = /^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/;
    //   if (list[list.length - 1].commissionEnd === "") {
    //     this.$message.error("请完善当前提现手续费后再添加");
    //     return;
    //   } else if (list[list.length - 1].price === "" || !reg.test(list[list.length - 1].price)) {
    //     this.$message.error("请输入不小于0的数字(小数点后最多两位)");
    //     return;
    //   }
    //   const start = list[list.length - 1].commissionEnd;
    //   list[list.length -1].isDisabled = true;
    //   list.push({
    //     commissionStart: start,
    //     commissionEnd: "",
    //     price: "",
    //     isDisabled: false,
    //   });
    //   this.model.commissionLadderList = list;
    // },
    // //删除一行阶梯计费
    // deleteLadder() {
    //   var list = this.model.commissionLadderList;
    //   if (list.length > 1) {
    //     list.pop();
    //     list[list.length -1].isDisabled = false;
    //     this.model.commissionLadderList = list;
    //   }
    // },

    //保存
    save() {
      this.$refs.model.validate((valid) => {
        if (valid) {
          const cardInfoVo = {
            account: this.model.account,
            accountOpeningBranchName: this.model.accountOpeningBranchName,
            bankAccountName: this.model.bankAccountName,
            bankHeadOfficeName: this.model.bankHeadOfficeName,
            bankHeadOfficeNumber: this.model.bankHeadOfficeNumber,
            bankType: this.model.bankType,
            id: this.accountInfoId,
          };
          const qualification = {
            businessLicenseAttachmentId: (this.model.businessLicenseAttachmentId).toString(),
            businessLicenseNumber: this.model.businessLicenseNumber,
            id: this.enterpriseQualificationId,
            legalPersonId: (this.model.legalPersonId).toString(),
            taxId: this.model.taxId,
          };
          const body = {
            accountPeriod: this.model.accountPeriod,
            cardInfoVo: cardInfoVo,
            id: this.settlementId,
            qualification: qualification,
          };
          this.isLoading = true;
          createOrUpdateBusinessInformation(body).then((res) => {
            this.isLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "保存结算方营业信息成功!",
                type: "success",
              });
            }
          });
        }
      });
    },

    //查询当前结算方的营业信息
    getBusinessInfo(id) {
      this.isLoading = true;
      findBusinessInformation(id).then((res) => {
        this.isLoading = false;
        if (res && res.code === 30 && res.result) {
          const returnObject = res.returnObject;
          this.enterpriseQualificationId = returnObject.qualification.id;
          this.accountInfoId = returnObject.cardInfoVo.id;
          this.model = {
            businessLicenseNumber:
            returnObject.qualification.businessLicenseNumber,
            taxId: returnObject.qualification.taxId,
            businessLicenseAttachmentId:
                returnObject.qualification.businessLicenseAttachmentId !== ""
                    ? returnObject.qualification.businessLicenseAttachmentId
                    : [],
            legalPersonId:
                returnObject.qualification.legalPersonId !== ""
                    ? returnObject.qualification.legalPersonId
                    : [],
            bankType: returnObject.cardInfoVo.bankType,
            bankHeadOfficeNumber: returnObject.cardInfoVo.bankHeadOfficeNumber,
            bankHeadOfficeName: returnObject.cardInfoVo.bankHeadOfficeName,
            accountOpeningBranchName: returnObject.cardInfoVo.accountOpeningBranchName,
            account: returnObject.cardInfoVo.account,
            bankAccountName: returnObject.cardInfoVo.bankAccountName,
            accountPeriod: (returnObject.accountPeriod) < 0 ? "" : returnObject.accountPeriod,
          };
          if (this.model.businessLicenseAttachmentId.length !== 0) {
            const licenseImage = [];
            licenseImage.push(this.model.businessLicenseAttachmentId);
            //获取图片列表
            this.getAllLicenseImages(licenseImage);
          }
          if (this.model.legalPersonId.length !== 0) {
            const legalImage = [];
            legalImage.push(this.model.legalPersonId);
            this.getAllLegalPersonImages(legalImage);
          }
        }
      });
    },
  },
  mounted() {
    // this.foundLadderList();//调用生成阶梯
    const settlementId = this.$route.query.id;
    if (settlementId) {
      // this.settlementId = settlementId;
      this.getBusinessInfo(settlementId);
    }
  },
};
</script>

<style lang="less" scoped>
.input-margin {
  margin-left: 8px;
}

.table-btn {
  text-align: center;
  margin-left: 200px;
  margin-bottom: 20px;
  margin-top: -8px;
}
</style>
